<template>
    <div class="spinner-box-wrap">
        <div class="spinner-box">
            <div class="circle-border">
                <div class="circle-core"></div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.spinner-box-wrap{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.8);
    z-index: 2;
    .spinner-box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}
</style>
