<template>
    <template v-if="Object.keys(shipmentWithCartItem).length > 0">
        <template v-for="(shipmentWithQty,cartKey,index) in shipmentWithCartItem">
            <dl class="shipment_with_cart_row">
                <dt class="item_name">
                    <span class="item_type" v-if="carts[cartKey].options['is_option'] === 1">オプション</span>
                    {{ carts[cartKey].name }}
                </dt>
                <dd class="item_shipment_qty_wrap">
                    <div class="item_shipment_qty flexible" v-for="(qty, shipmentKey) in shipmentWithQty">
                        <p class="item_qty">{{ qty }} 点</p>
                        <p>{{ shipments[shipmentKey].name }} 様</p>
                    </div>
                </dd>
            </dl>
        </template>
        <button type="button" class="base_btn _gray _auto _margin_auto flexible mt-20"
                @click="deleteCartMixedShipmentInfo">
            <span class="icon _unlock _size_pattern_2"></span>
            <span class="ml-10">設定を解除する</span>
        </button>
        <p class="form_error_msg al-center">{{ props.validateMessage }}</p>
    </template>
    <dl v-if="Object.keys(shipmentWithCartItem).length  === 0" class="shipment_with_cart_row">お届け先が未設定です</dl>
</template>

<script setup>
const props = defineProps(['carts', 'shipments', 'shipmentWithCartItem', 'deleteUrl', 'validateMessage']);
const emit = defineEmits(['show-flash-message', 'get-shipment-with-cart-info']);

async function deleteCartMixedShipmentInfo() {
    await axios.post(props.deleteUrl).then((resp) => {
        const apiResponse = resp.data;
        if (apiResponse.statusCode !== 200) {
            emit('show-flash-message', {'message': '商品のお届け先設定のリセットに失敗しました。', 'status': false});
            return false;
        }
        emit('show-flash-message', {'message': "商品のお届け先設定をリセットしました。"});
        emit('get-shipment-with-cart-info');
    }).catch((error) => {
        let message = Object.values(error.response.data.errors).join(',');
        emit('show-flash-message', {'message': message, 'status': false});
        return false;
    });
}
</script>
<style lang="scss" scoped>
.item_type {
    font-size: .8rem;
}
</style>
