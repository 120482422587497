<template>
    <template v-for="(qty,cartKey) in cartQuantityOfNotSet">
        <dl class="shipment_with_cart_row" v-if="qty > 0">
            <dt class="item_name">
                <span class="item_type" v-if="carts[cartKey].options['is_option'] === 1">オプション</span>
                {{ carts[cartKey].name }}
            </dt>
            <dd class="item_flex flexible">
                <p>{{ qty }} 点</p>
                <button type="button" class="base_btn _blue _auto _margin_auto flexible"
                        @click="parentShowModal('shipmentWithCartAdd', {'url': addUrl, 'inputArray': {
                            item: carts[cartKey],
                            limit: qty,
                            shipments: shipments,
                        }})">
                    この商品のお届け先を設定する
                </button>
            </dd>
        </dl>
    </template>
    <input type="hidden" name="exist_not_set_item" v-model="isExistsNotSetCartItem">
    <dl v-if="!isExistsNotSetCartItem" class="shipment_with_cart_row">すべての商品のお届け先設定済み</dl>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";

const props = defineProps(['shipments', 'carts', 'addUrl', 'shipmentWithCartItem']);
const emit = defineEmits(['show-modal']);
const cartQuantityOfNotSet = ref({});
const isExistsNotSetCartItem = computed(() => {
    return Object.values(cartQuantityOfNotSet.value).some(value => value > 0);
});

onMounted(() => {
    const cartKeyWithQty = shapeJSONToCartKeyWithQuantity(props.shipmentWithCartItem) ?? [];
    getCartQuantityOfNotSet(cartKeyWithQty);
});


watch(props, () => {
    const cartKeyWithQty = shapeJSONToCartKeyWithQuantity(props.shipmentWithCartItem) ?? [];
    getCartQuantityOfNotSet(cartKeyWithQty);
});

async function parentShowModal(modalName, argArray) {
    emit('show-modal', modalName, argArray);
}

/*
* セッションから取得した商品お届け先情報をカートID: 数量　の形に変換する
*
*
*/
function shapeJSONToCartKeyWithQuantity(input) {
    const result = {};
    for (const cartID in input) {
        const deliveries = input[cartID];
        let total = 0;

        for (const deliveryID in deliveries) {
            total += deliveries[deliveryID];
        }

        result[cartID] = total;
    }
    return result;
}

/*
* カートの数量とセッション設定済みの数量を比較し、未設定の商品と数量を返す
*
*/
function getCartQuantityOfNotSet(cartKeyWithQty) {
    for (const cartId in props.carts) {
        const quantity = props.carts[cartId].qty;
        const hasKey = Object.keys(cartKeyWithQty).some(objCardId => objCardId === cartId);
        if (!hasKey) {
            cartQuantityOfNotSet.value[cartId] = quantity;
            continue;
        }
        const sessionObjQty = cartKeyWithQty[cartId];
        cartQuantityOfNotSet.value[cartId] = quantity - sessionObjQty;
    }
}
</script>
<style lang="scss" scoped>
.item_type {
    font-size: .8rem;
}
</style>
