<template>
    <label class="checkbox-001 mt-15">
        <input type="radio"
               name="same_delivery_address"
               v-model="checkSameAddress"
               value="1"
               class="checkbox-001-input">
        <span class="checkbox-001-dummyInput"></span>
        <span class="checkbox-001-labelText">ご自宅へお届け</span>
    </label>
    <label class="checkbox-001 mt-15">
        <input type="radio"
               name="same_delivery_address"
               v-model="checkSameAddress"
               value="0"
               class="checkbox-001-input">
        <span class="checkbox-001-dummyInput"></span>
        <span class="checkbox-001-labelText">ご自宅以外へお届け</span>
    </label>
</template>

<script setup>
import {watch, ref, onMounted} from "vue";

const props = defineProps(['sameDeliveryFlg']);
const emit = defineEmits(['bridge-same-address-flg']);
const checkSameAddress = ref();

onMounted(() => {
    checkSameAddress.value = props.sameDeliveryFlg;
    emit('bridge-same-address-flg', checkSameAddress.value);
});
watch(checkSameAddress, () => {
    emit('bridge-same-address-flg', checkSameAddress.value);
});
</script>
