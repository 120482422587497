<template>
    <transition>
        <div class="flash_message top font-noto"
             :class="{'success': statusSuccess, 'error': !statusSuccess}"
             v-if="flashDisplay"
        >
            <div class="icon_caution">
                <img src="/img/icons/complete.svg" alt="成功" v-if="statusSuccess">
                <img src="/img/icons/caution.svg" alt="注意" v-if="!statusSuccess">
            </div>
            <p v-html="message"></p>
        </div>
    </transition>
</template>
<script setup>
import {ref} from "vue";

const message = ref('');
const flashDisplay = ref(false);
const statusSuccess = ref(false);

function showFlashMessage(args) {
    flashDisplay.value = true;
    message.value = args.message ?? '';
    statusSuccess.value = args.status ?? true;
    setTimeout(() => {
        hideFlashMessage();
    }, 5000);
}

function hideFlashMessage() {
    flashDisplay.value = false;
    message.value = '';
    statusSuccess.value = false;
}

defineExpose({
    showFlashMessage,
});
</script>
<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity .3s;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
