<template>
    <div :class="{'form_error_area':validateMessage}" v-if="!sameDeliveryFlg">
        <p class="form_error_msg" v-for="(message, index) in props.validateMessage" :key="index">
            {{ message }}
        </p>
        <div class="area_formal_form area_delivery_list">
            <p class="area_formal_form_title">お届け先が未設定の商品</p>
            <cart-item-not-set-area ref="cartItemNotSetArea"
                                    @show-modal="parentShowModal"
                                    :carts="carts"
                                    :shipments="shipments"
                                    :add-url="addUrl"
                                    :shipment-with-cart-item="shipmentWithCartItem"
            >
            </cart-item-not-set-area>
        </div>

        <div class="area_formal_form area_delivery_list">
            <p class="area_formal_form_title">お届け先が設定済みの商品</p>
            <cart-item-already-set-area ref="cartItemAlreadySetArea"
                                        @get-shipment-with-cart-info="parentGetShipmentWithCartInfo"
                                        @show-flash-message="parentShowFlashMessage"
                                        :shipments="shipments"
                                        :carts="carts"
                                        :shipment-with-cart-item="shipmentWithCartItem"
                                        :delete-url="deleteUrl"
            >
            </cart-item-already-set-area>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";

const props = defineProps(['shipments', 'carts', 'selectUrl', 'addUrl', 'deleteUrl', 'validateMessage', 'sameDeliveryFlg']);
const emit = defineEmits(['show-flash-message', 'show-modal', 'get-shipment-with-cart-info']);
const shipmentWithCartItem = ref({});

onMounted(() => {
    getShipmentWithCart();
});

function parentShowFlashMessage(args) {
    emit('show-flash-message', args);
}

async function parentShowModal(modalName, argArray) {
    emit('show-modal', modalName, argArray);
}

function parentGetShipmentWithCartInfo() {
    emit('get-shipment-with-cart-info');
}

async function getShipmentWithCart() {
    await axios.get(props.selectUrl).then((resp) => {
        const apiResponse = resp.data;
        if (apiResponse.statusCode !== 200) {
            emit('show-flash-message', {'message': '商品お届け先設定の取得に失敗しました。', 'status': false});
            return false;
        }
        shipmentWithCartItem.value = apiResponse.result ?? [];

    }).catch((error) => {
        emit('show-flash-message', {'message': '商品お届け先設定の取得に失敗しました。', 'status': false});
    });
}

defineExpose({
    getShipmentWithCart,
});
</script>
