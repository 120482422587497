<template>
    <div class="validation_msg_area" v-if="validationMsgArray">
        <template v-for="(messages, errorName) in validationMsgArray" :key="errorName">
            <template v-if="isNotString(messages)">
                <p v-for="message in messages">{{ message }}</p>
            </template>
            <template v-else>
                <p>{{ messages }}</p>
            </template>

        </template>
    </div>
</template>

<script setup>
const props = defineProps(['validationMsgArray']);

function isNotString(value) {
    return typeof value !== 'string';
}
</script>
