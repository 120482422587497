<template>
    <div class="base_padding" v-if="!sameDeliveryFlg">
        <p class="area_formal_form_title">お届け先リスト</p>
        <dl class="dl_purchase_shipment" :class="{'form_error_area':validateMessage}">
            <dd v-if="!isEmpty(orderDeliveryContacts)">
                <p class="form_error_msg">{{ props.validateMessage }}</p>
                <div class="shipment_area">
                    <div class="shipment_box flexible _flex-start _gap-style-1 mt-10"
                         v-for="(orderDeliveryContact, key) in orderDeliveryContacts">
                        <p class="shipment_box_name">{{ orderDeliveryContact.name }} 様</p>
                        <p class="shipment_box_address">
                            <span class="postal">〒{{ orderDeliveryContact.postal }}</span>
                            <span class="address">{{ orderDeliveryContact.address }}</span>
                        </p>
                        <p class="shipment_box_tel">TEL：{{ orderDeliveryContact.tel }}</p>
                        <p class="shipment_box_date">お届け日時：{{ orderDeliveryContact.delivery_date ?? '指定なし' }}</p>
                        <p class="shipment_box_timezone">
                            時間帯：{{
                                DeliveryTimeZone.TIME_ZONE_DISP_NAME[orderDeliveryContact.delivery_timezone]
                            }}</p>
                        <button type="button" class="" @click="deleteShipmentInfo(key)">
                            <span class="icon _size_pattern_4 _garbage"></span>
                        </button>
                    </div>
                </div>
            </dd>
            <button type="button" class="base_btn _auto flexible"
                    @click="parentShowModal('shipmentAdd', {'url': addUrl})">
                <span class="icon _plus _size_pattern_3"></span>
                <span class="ml-20">お届け先を追加する</span>
            </button>
        </dl>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {isEmpty} from "../functions/global";
import {DeliveryTimeZone} from "../enums/DeliveryTimeZone";

const props = defineProps(['addUrl', 'selectUrl', 'deleteUrl', 'shipmentWithCartSelectUrl', 'validateMessage', 'sameDeliveryFlg']);
const emit = defineEmits(['show-modal', 'show-flash-message', 'set-latest-deliveries']);
const orderDeliveryContacts = ref({});

onMounted(() => {
    getShipmentInfo();
});

async function parentShowModal(modalName, argArray) {
    const sameFlg = document.getElementsByName('same_delivery_address')[0] ?? null;
    if (sameFlg && sameFlg.checked) {
        emit('show-flash-message', {'message': '購入者住所へのお届けご希望の場合はほかのお届け先の追加はできません。', 'status': false});
        return;
    }
    emit('show-modal', modalName, argArray);
}

async function getShipmentInfo() {
    await axios.get(props.selectUrl).then((resp) => {
        const apiResponse = resp.data;
        if (apiResponse.statusCode !== 200) {
            emit('show-flash-message', {'message': 'お届け先情報の取得に失敗しました。', 'status': false});
            return false;
        }
        orderDeliveryContacts.value = apiResponse.result ?? [];
        emit('set-latest-deliveries', orderDeliveryContacts.value);

    }).catch((error) => {
        emit('show-flash-message', {'message': 'お届け先情報の取得に失敗しました。', 'status': false});
    });
}

async function getSessionOfShipmentWithCart() {
    return await axios.get(props.shipmentWithCartSelectUrl).then((resp) => {
        const apiResponse = resp.data;

        if (apiResponse.statusCode !== 200) {
            return [];
        }
        return apiResponse.result;

    }).catch((error) => {
        return [];
    });
}

async function deleteShipmentInfo(targetKey) {
    let result = await getSessionOfShipmentWithCart();
    // お届け先IDだけの配列に変換
    const destinationSet = new Set();
    for (const cartId in result) {
        const destinations = result[cartId];
        for (const destinationId in destinations) {
            destinationSet.add(destinationId);
        }
    }
    const destinationArray = Array.from(destinationSet);

    if (destinationArray.includes(targetKey)) {
        emit('show-flash-message', {
            'message': "このお届け先はすでに商品設定がされているため削除できません。<br>商品設定を解除してから再度お試しください。",
            'status': false
        });
        return false;
    }

    await axios.post(props.deleteUrl, {
        'target_key': targetKey,
    }).then((resp) => {
        const apiResponse = resp.data;
        if (apiResponse.statusCode !== 200) {
            emit('show-flash-message', {'message': 'お届け先情報の削除に失敗しました', 'status': false});
            return false;
        }
        emit('show-flash-message', {'message': 'お届け先情報を削除しました'});
        orderDeliveryContacts.value = apiResponse.result;
        emit('set-latest-deliveries', orderDeliveryContacts.value);

    }).catch((error) => {
        emit('show-flash-message', {'message': 'お届け先情報の削除に失敗しました', 'status': false});
    });
}

defineExpose({
    getShipmentInfo,
});
</script>
