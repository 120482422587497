<template>
    <div class="modal_wrap">
        <p class="modal_text">お届け先の追加</p>
        <div class="validation_msg_area_wrap mt-15">
            <ValidationMessageComponent :validationMsgArray/>
        </div>
        <div class="modal_form">
            <dl class="dl_purchase flexible">
                <dt>お届け先の氏名</dt>
                <dd>
                    <input type="text" v-model="newShipmentInfo.shipment_name" class="textbox-002"
                           placeholder="例）とらふぐ 太郎">
                </dd>
            </dl>
            <dl class="dl_purchase flexible">
                <dt>郵便番号</dt>
                <dd>
                    <input type="text" name="shipment_postal" v-model="newShipmentInfo.shipment_postal"
                           class="textbox-002" placeholder="例）0000000">
                </dd>
            </dl>
            <dl class="dl_purchase flexible">
                <dt>住所</dt>
                <dd>
                    <input type="text" name="shipment_address" v-model="newShipmentInfo.shipment_address"
                           class="textbox-002" placeholder="例）大分県〇〇市△△町5-5-5 ハイツ駅前501">
                    <p class="notion">※北海道、沖縄への配送には送料 +￥1,000が加算されます。</p>
                </dd>
            </dl>
            <dl class="dl_purchase flexible">
                <dt>電話番号</dt>
                <dd>
                    <input type="tel" name="shipment_tel" v-model="newShipmentInfo.shipment_tel" class="textbox-002"
                           placeholder="例）092-××××-0000">
                </dd>
            </dl>
            <dl class="dl_purchase flexible">
                <dt>お届け日時の指定</dt>
                <dd>
                    <label class="selectbox-003 _middle">
                        <select name="shipment_date" v-model="newShipmentInfo.shipment_date">
                            <option value="" :selected="newShipmentInfo.shipment_date.length === 0">指定なし</option>
                            <option :value="value" v-for="value in getFromAfterWeekToOneMonthLater()"
                                    :selected="newShipmentInfo.shipment_date === value">{{ value }}
                            </option>
                        </select>
                    </label>

                    <p class="notion">※お届け日は購入日より一週間後からご指定いただけます。</p>
                </dd>
            </dl>
            <dl class="dl_purchase flexible last">
                <dt>受取希望時間帯</dt>
                <dd>
                    <label class="selectbox-003 _middle">
                        <select name="shipment_timezone" v-model="newShipmentInfo.shipment_timezone">
                            <option v-for="(value,key) in DeliveryTimeZone.TIME_ZONE_DISP_NAME" :value="key"
                                    :selected="key === newShipmentInfo.shipment_timezone">{{ value }}
                            </option>
                        </select>
                    </label>
                </dd>
            </dl>
        </div>

        <div class="modal_btn_area">
            <button class="base_btn _normal _f_min" @click="parentCloseModal">閉じる</button>
            <button class="base_btn _normal _f_min btn-mg-left" @click="addDeliveryInfoToSession">追加</button>
        </div>
    </div>
</template>

<script setup>
import {DeliveryTimeZone} from "../enums/DeliveryTimeZone";
import {errorHandler, getFromAfterWeekToOneMonthLater} from "../functions/global";
import {ref} from "vue";
import ValidationMessageComponent from "./ValidationMessageComponent.vue";

const validationMsgArray = ref([]);
const props = defineProps(['action']);
const emit = defineEmits(['close-modal', 'loading-start', 'loading-end', 'show-flash-message']);
const newShipmentInfo = ref({
    'shipment_name': '',
    'shipment_postal': '',
    'shipment_address': '',
    'shipment_tel': '',
    'shipment_date': '',
    'shipment_timezone': 1,
});

function parentCloseModal() {
    emit('close-modal');
}

async function addDeliveryInfoToSession() {
    const apiUrl = props.action;
    emit('loading-start');

    await axios.post(apiUrl, newShipmentInfo.value).then((resp) => {
        const apiResponse = resp.data;
        if (apiResponse.statusCode !== 200) {
            validationMsgArray.value = errorHandler(apiResponse.result);
            emit('loading-end');
        }
        emit('loading-end');
        emit('close-modal');
        emit('show-flash-message', {'message': 'お届け先情報を追加しました'});
        emit('get-shipment-info');
    }).catch((error) => {
        validationMsgArray.value = errorHandler(error);
        emit('loading-end');
    });
}


</script>
