const TIME_ZONE = {
    UNSPECIFIED: 1,
    TIME_IN_THE_MORNING: 2,
    TIME_14_16: 3,
    TIME_16_18: 4,
    TIME_18_20: 5,
    TIME_19_21: 6,
}

const DeliveryTimeZone = {
    TIME_ZONE_DISP_NAME: {
        [TIME_ZONE.UNSPECIFIED]: '指定なし',
        [TIME_ZONE.TIME_IN_THE_MORNING]: '午前中',
        [TIME_ZONE.TIME_14_16]: '14:00 ～ 16:00',
        [TIME_ZONE.TIME_16_18]: '16:00 ～ 18:00',
        [TIME_ZONE.TIME_18_20]: '18:00 ～ 20:00',
        [TIME_ZONE.TIME_19_21]: '19:00 ～ 21:00',
    },
}
export {DeliveryTimeZone};
