const moment = require('moment');

/*スクロールバーの幅取得*/
export function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
}

/*1週間後を起点として現在日より1カ月後までの日付文字列を配列で返却する*/
export function getFromAfterWeekToOneMonthLater() {
    let dates = [];
    let currentDate = moment().add(1, 'week'); // 1週間後から開始
    const endDate = moment().add(1, 'month');

    while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
    }

    return dates;
}

/*axiosエラーかつVueファイルの場合、バリデーションメッセージを設定する*/
export function errorHandler(error) {
    let validationMsgArray;
    if (error.response) {
        validationMsgArray = error.response.data.errors;
    } else {
        validationMsgArray = error;
    }

    if (!validationMsgArray) {
        validationMsgArray = {
            'message': '処理中にエラーが発生しました。'
        }
    }

    return validationMsgArray;
}

export function isEmpty(obj) {
    return Object.keys(obj).length === 0
}
