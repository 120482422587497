import {commonInit} from "./common";
import {createApp} from "vue";
import {onMounted, ref} from "vue";
import ModalComponent from "./components/ModalComponent.vue";
import FlashMessage from "./components/FlashMessage.vue";
import ShipmentInfoArea from "./components/ShipmentInfoArea.vue";
import DeliveryItemSelectArea from "./components/DeliveryItemSelectArea.vue";
import CartItemNotSetArea from "./components/CartItemNotSetArea.vue";
import CartItemAlreadySetArea from "./components/CartItemAlreadySetArea.vue";
import ShipmentWithCartItem from "./components/ShipmentWithCartItem.vue";
import CheckboxDeliveryTo from "./components/CheckboxDeliveryTo.vue";

require('./bootstrap');
require('axios');

const app = createApp({
    setup() {
        const modalComponent = ref(null);
        const flashMessage = ref(null);
        const shipmentInfoArea = ref(null);
        const deliveryItemSelectArea = ref(null);
        const cartItemNotSetArea = ref(null);
        const cartItemAlreadySetArea = ref(null);
        const shipmentWithCartItem = ref(null);
        const checkboxDeliveryTo = ref(null);
        const metaCsrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        const csrf = ref(metaCsrf);
        let shipmentInfoData = ref(null);
        let sameDeliveryFlg = ref(1);

        onMounted(() => {
            commonInit();
        });

        function showModal(modalName, argArray) {
            modalComponent.value?.showModal(modalName, argArray);
        }

        function showFlashMessage(args) {
            flashMessage.value?.showFlashMessage(args);
        }

        function getShipmentInfo() {
            shipmentInfoArea.value?.getShipmentInfo();
        }

        function getShipmentWithCartInfo() {
            shipmentWithCartItem.value?.getShipmentWithCart();
        }

        function setLatestShipmentInfo(latestData) {
            shipmentInfoData.value = latestData;
            if (latestData.length === 0) {
                document.querySelectorAll('[data-required-shipment]').forEach(element => {
                    element.classList.add('disp-none-element');
                });
                return;
            }
            document.querySelectorAll('[data-required-shipment]').forEach(element => {
                element.classList.remove('disp-none-element');
            });
        }

        function bridgeSameAddressFlg(boolVal) {
            sameDeliveryFlg.value = Number(boolVal);
        }

        return {
            modalComponent,
            flashMessage,
            shipmentInfoArea,
            deliveryItemSelectArea,
            cartItemNotSetArea,
            cartItemAlreadySetArea,
            shipmentWithCartItem,
            checkboxDeliveryTo,
            metaCsrf,
            csrf,
            shipmentInfoData,
            sameDeliveryFlg,
            onMounted,
            showModal,
            showFlashMessage,
            getShipmentInfo,
            getShipmentWithCartInfo,
            setLatestShipmentInfo,
            bridgeSameAddressFlg,
        }
    },
});
app.component('ModalComponent', ModalComponent);
app.component('FlashMessage', FlashMessage);
app.component('ShipmentInfoArea', ShipmentInfoArea);
app.component('DeliveryItemSelectArea', DeliveryItemSelectArea);
app.component('CartItemNotSetArea', CartItemNotSetArea);
app.component('CartItemAlreadySetArea', CartItemAlreadySetArea);
app.component('ShipmentWithCartItem', ShipmentWithCartItem);
app.component('CheckboxDeliveryTo', CheckboxDeliveryTo);
app.mount('#app');
