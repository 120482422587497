<template>
    <div class="modal_wrap">
        <p class="modal_text">商品のお届け先を設定する</p>
        <div class="validation_msg_area_wrap">
            <ValidationMessageComponent :validationMsgArray/>
        </div>
        <div class="modal_form" v-if="isExistsShipments">
            <dl>
                <dt>商品名</dt>
                <dt>数量</dt>
                <dt>宛先</dt>
            </dl>
            <dl>
                <dd>
                    {{ inputArray.item.name }}
                </dd>
                <dd>
                    <label class="selectbox-003 _middle">
                        <select v-model="newShipmentWithCartInfo.item_qty" class="select_cart_with_delivery">
                            <option :value="n" v-for="n in inputArray.limit">
                                {{ n }}
                            </option>
                        </select>
                    </label>
                </dd>
                <dd>
                    <label class="selectbox-003 _middle">
                        <select v-model="newShipmentWithCartInfo.shipment_key" class="select_cart_with_delivery">
                            <option value="" selected>-</option>
                            <option :value="key" v-for="(shipment, key) in inputArray.shipments">
                                {{ shipment.name }} 様
                            </option>
                        </select>
                    </label>
                </dd>
            </dl>
        </div>
        <div class="modal_content" v-if="!isExistsShipments">
            <p>お届け先が登録されていません。</p>
        </div>
        <div class="modal_btn_area">
            <button class="base_btn _normal _f_min" @click="parentCloseModal">閉じる</button>
            <button class="base_btn _normal _f_min btn-mg-left" @click="addDeliveryInfoToSession"
                    v-if="isExistsShipments">追加
            </button>
        </div>
    </div>
</template>

<script setup>
import {errorHandler} from "../functions/global";
import {computed, ref} from "vue";
import ValidationMessageComponent from "./ValidationMessageComponent.vue";

const validationMsgArray = ref([]);
const props = defineProps(['action', 'inputArray']);
const emit = defineEmits(['close-modal', 'loading-start', 'loading-end', 'show-flash-message', 'get-shipment-with-cart-info']);
const newShipmentWithCartInfo = ref({
    'cart_key': props.inputArray.item.rowId,
    'shipment_key': '',
    'item_qty': '',
});
const isExistsShipments = computed(() => {
    return props.inputArray.shipments && Object.keys(props.inputArray.shipments).length > 0;
});

function parentCloseModal() {
    emit('close-modal');
}

async function addDeliveryInfoToSession() {
    const apiUrl = props.action;
    emit('loading-start');

    await axios.post(apiUrl, newShipmentWithCartInfo.value).then((resp) => {
        const apiResponse = resp.data;
        if (apiResponse.statusCode !== 200) {
            validationMsgArray.value = errorHandler(apiResponse.result);
            emit('loading-end');
        }
        emit('loading-end');
        emit('close-modal');
        emit('show-flash-message', {'message': '商品のお届け先を設定しました'});
        emit('get-shipment-with-cart-info');
    }).catch((error) => {
        validationMsgArray.value = errorHandler(error);
        emit('loading-end');
    });
}


</script>
<style lang="scss" scoped>
.modal_form {
    dl {
        display: grid;
        grid-template-columns: 55% 13% 22%;
        gap: calc(10% / 2);
        margin-bottom: 0;
        padding-bottom: 0;
        padding-top: 10px;
        @media screen and (max-width: 767px) {
            gap: 5px;
            grid-template-columns: 50% 20% 1fr;
        }
    }

    .select_cart_with_delivery {
        @media screen and (max-width: 767px) {
            padding: 0 5px;
        }
    }
}

.modal_btn_area {
    margin-top: 15px;
}
</style>
