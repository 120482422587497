<template>
    <!-- お届け先設定が未保存の場合 -->
    <template v-if="!orderDeliveryContactsWithCart || Object.keys(orderDeliveryContactsWithCart).length === 0">
        <template v-for="(item,cartKey,index) in carts">
            <dl class="cart_item_row head dl_purchase_cart_row" v-if="index === 0">
                <dt class="item_name">商品名</dt>
                <dt class="item_flex">お届け先</dt>
            </dl>
            <dl class="cart_item_row dl_purchase_cart_row" v-for="n in item.qty">
                <dd class="item_name">{{ item.name }}</dd>
                <dd class="item_flex select-flex">
                    <label class="selectbox-003 _middle">
                        <select :name="cartKey" class="select_cart_with_delivery">
                            <option value="" selected>-</option>
                            <option :value="key" v-for="(orderDeliveryContact, key) in orderDeliveryContacts">
                                {{ orderDeliveryContact.name }} 様
                            </option>
                        </select>
                    </label>
                </dd>
            </dl>
        </template>
        <button type="button" class="base_btn _green _auto _margin_auto flexible mt-20"
                @click="settingCartMixedShipmentInfo">
            <span class="icon _check_block_green _size_pattern_3"></span>
            <span class="ml-10">商品の配送先を確定する</span>
        </button>
        <p class="caution al-center">※確定するまでは配送先設定が保存されません。</p>
        <p class="form_error_msg al-center">{{ props.validateMessage }}</p>
    </template>
    <!-- お届け先設定が保存済みの場合 -->
    <template v-if="Object.keys(orderDeliveryContactsWithCart).length > 0">
        <template v-for="(withDeliveryKeys,withCartKey,index) in orderDeliveryContactsWithCart">
            <dl class="cart_item_row head dl_purchase_cart_row" v-if="index === 0">
                <dt class="item_name">商品名</dt>
                <dt class="item_flex">お届け先</dt>
            </dl>
            <dl class="cart_item_row dl_purchase_cart_row"
                v-for="withDeliveryKey in withDeliveryKeys">
                <dd class="item_name">{{ carts[withCartKey].name }}</dd>
                <dd class="item_flex select-flex">
                    <div class="icon _size_pattern_3"><img src="/img/icons/complete.svg"
                                                           alt="保存済み"></div>
                    {{ orderDeliveryContacts[withDeliveryKey].name }} 様
                </dd>
            </dl>
        </template>
        <button type="button" class="base_btn _red _auto _margin_auto flexible mt-20"
                @click="deleteCartMixedShipmentInfo">
            <span class="icon _unlock _size_pattern_3"></span>
            <span class="ml-10">配送先設定を解除する</span>
        </button>
        <p class="form_error_msg al-center">{{ props.validateMessage }}</p>
    </template>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";

const props = defineProps(['shipments', 'carts', 'selectUrl', 'storeUrl', 'deleteUrl', 'validateMessage']);
const emit = defineEmits(['change-cart-mixed-shipment-info', 'show-flash-message']);
const orderDeliveryContacts = ref({});
const orderDeliveryContactsWithCart = ref({});
const carts = ref({});

onMounted(() => {
    carts.value = props.carts;
    orderDeliveryContacts.value = props.shipments;
    getShipmentWithCart();
});

watch(props, () => {
    orderDeliveryContacts.value = props.shipments;
    getShipmentWithCart();
});

async function settingCartMixedShipmentInfo(e) {
    if (validateCartMixedShipmentInfo()) {
        emit('show-flash-message', {'message': '商品のお届け先情報はすべての商品に設定してください。', 'status': false});
        return false;
    }

    let originalObjectArray = [];
    for (let cartKey in carts.value) {
        const selectElements = document.getElementsByName(cartKey);
        selectElements.forEach((selectElement) => {
            originalObjectArray.push({
                [cartKey]: selectElement.value,
            });
        });
    }

    const submitArray = shapedSubmitArray(originalObjectArray);

    if (!submitArray) {
        emit('show-flash-message', {'message': '商品のお届け先情報が正しく設定されていません。', 'status': false});
        return false;
    }

    await axios.post(props.storeUrl, {
        'input_array': submitArray,
    }).then((resp) => {
        const apiResponse = resp.data;
        if (apiResponse.statusCode !== 200) {
            emit('show-flash-message', {'message': '商品のお届け先の保存に失敗しました。', 'status': false});
            return false;
        }
        emit('show-flash-message', {'message': '商品のお届け先を設定しました'});
        orderDeliveryContactsWithCart.value = decodeSubmitArray(apiResponse.result) ?? [];
    }).catch((error) => {
        let message = Object.values(error.response.data.errors).join(',');
        emit('show-flash-message', {'message': message, 'status': false});
    });
}

function shapedSubmitArray(inputArray) {
    const result = {};

    inputArray.forEach(obj => {
        const cartId = Object.keys(obj)[0];
        const deliveryId = obj[cartId];

        if (!result[cartId]) {
            result[cartId] = {};
        }

        if (!result[cartId][deliveryId]) {
            result[cartId][deliveryId] = 0;
        }

        result[cartId][deliveryId] += 1;
    });

    return result;
}

function decodeSubmitArray(input) {
    const result = {};
    for (const cartID in input) {
        const destinations = input[cartID];
        for (const destID in destinations) {
            const count = destinations[destID];
            for (let i = 0; i < count; i++) {
                if (!result[cartID]) {
                    result[cartID] = [];
                }
                result[cartID].push(destID);
            }
        }
    }
    return result;
}

async function getShipmentWithCart() {
    await axios.get(props.selectUrl).then((resp) => {
        const apiResponse = resp.data;
        if (apiResponse.statusCode !== 200) {
            emit('show-flash-message', {'message': '商品お届け先設定の取得に失敗しました。', 'status': false});
            return false;
        }
        orderDeliveryContactsWithCart.value = decodeSubmitArray(apiResponse.result) ?? [];

    }).catch((error) => {
        emit('show-flash-message', {'message': '商品お届け先設定の取得に失敗しました。', 'status': false});
    });
}

function validateCartMixedShipmentInfo() {
    const selectElements = Array.from(document.getElementsByClassName('select_cart_with_delivery'));
    return selectElements.some(selectElement => selectElement.value === '' || selectElement.value === null);
}

async function deleteCartMixedShipmentInfo() {
    await axios.post(props.deleteUrl).then((resp) => {
        const apiResponse = resp.data;
        if (apiResponse.statusCode !== 200) {
            emit('show-flash-message', {'message': '商品の配送先設定解除に失敗しました。', 'status': false});
            return false;
        }
        emit('show-flash-message', {'message': "商品のお届け先設定を解除しました。"});
        orderDeliveryContactsWithCart.value = decodeSubmitArray(apiResponse.result) ?? [];
    }).catch((error) => {
        let message = Object.values(error.response.data.errors).join(',');
        emit('show-flash-message', {'message': message, 'status': false});
        return false;
    });
}
</script>

<style lang="scss" scoped>
.select-flex {
    display: flex;
    align-items: center;

    .icon {
        display: flex;
        margin-right: 15px;
    }
}

dl.cart_item_row .item_name {
    width: 70%;
}

option {
    text-align: left;
}
</style>
