<template>
    <transition>
        <div v-show="modalDisplay" @click="layorClick" class="modal_back">
            <div class="modal _large-style-1 font-noto" :class="{'loading': loading}">
                <!--ローディング-->
                <LoadingComponent v-if="loading"/>
                <!--お届け先追加-->
                <transition>
                    <div v-if="modalName === 'shipmentAdd'" class="modal_wrap">
                        <ModalAddShipmentInfo @close-modal="closeModal"
                                              @loading-start="loadingStart"
                                              @loading-end="loadingEnd"
                                              @show-flash-message="parentShowFlashMessage"
                                              @get-shipment-info="parentGetShipmentInfo"
                                              :csrf="csrf" :action="actionUrl"/>
                    </div>
                </transition>
                <!--商品お届け先設定追加-->
                <transition>
                    <div v-if="modalName === 'shipmentWithCartAdd'" class="modal_wrap">
                        <ModalAddShipmentWithCartInfo @close-modal="closeModal"
                                                      @loading-start="loadingStart"
                                                      @loading-end="loadingEnd"
                                                      @show-flash-message="parentShowFlashMessage"
                                                      @get-shipment-with-cart-info="parentGetShipmentWithCartInfo"
                                                      :csrf="csrf" :action="actionUrl" :inputArray="inputArray"/>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script setup>
import {ref} from "vue";
import {getScrollbarWidth} from "../functions/global";
import LoadingComponent from "./LoadingComponent.vue";
import ModalAddShipmentInfo from "./ModalAddShipmentInfo.vue";
import ModalAddShipmentWithCartInfo from "./ModalAddShipmentWithCartInfo.vue";


const modalName = ref('');
const modalDisplay = ref(false);
const targetId = ref(null);
const inputArray = ref(null);
const actionUrl = ref('');
const loading = ref(false);
const emit = defineEmits(['show-flash-message', 'get-shipment-with-cart-info']);
const props = defineProps(['csrf']);

function showModal(inputModalName, argArray = null) {
    const scrollBarWidth = getScrollbarWidth();
    document.body.style.paddingRight = scrollBarWidth + 'px';
    document.body.style.overflow = 'hidden';
    modalDisplay.value = true;
    modalName.value = inputModalName;
    actionUrl.value = argArray.url ?? null;
    targetId.value = argArray.targetId ?? null;
    inputArray.value = argArray.inputArray ?? null;
}

function closeModal() {
    document.body.style.paddingRight = 0;
    document.body.style.overflow = '';
    modalName.value = '';
    modalDisplay.value = false;
    targetId.value = null;
    actionUrl.value = '';
    inputArray.value = null;
}

function layorClick(event) {
    if (event.target.closest('.modal') === null) {
        closeModal()
    }
}

function loadingStart() {
    loading.value = true;
}

function loadingEnd() {
    loading.value = false;
}

function parentShowFlashMessage(args) {
    emit('show-flash-message', args);
}

function parentGetShipmentInfo() {
    emit('get-shipment-info');
}

function parentGetShipmentWithCartInfo() {
    emit('get-shipment-with-cart-info');
}

defineExpose({
    showModal,
});
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity .3s;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
